import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Container from 'components/Container';
import HeroModuleHeader from 'components/HeroModuleHeader';
import TextBox from 'components/TextBox';

import {
  JobsSection,
  BoxesRow,
  TextBoxWrap,
  BoxesColumn,
  ContainerWrapper,
  ButtonWrapper,
} from './JobsLatestStyled';

function JobsLatest({ data, headline, image, text }) {
  const entries = data?.craft?.entries;
  return (
    <JobsSection>
      <HeroModuleHeader
        backgroundImage={image}
        headline={headline}
        text={text}
        aspectRatio="1:4"
        position="absolute"
        top="0"
        left="0"
      />
      <Container position="relative">
        <ContainerWrapper>
          {entries && (
            <BoxesRow width="80%" justifyContent="space-between">
              {entries.map(entry => (
                <BoxesColumn width="50%" key={entry.id}>
                  <TextBoxWrap>
                    <TextBox
                      title={entry.title}
                      text={entry.jobsIntro}
                      link={entry.fullUri}
                    />
                  </TextBoxWrap>
                </BoxesColumn>
              ))}
            </BoxesRow>
          )}
        </ContainerWrapper>
      </Container>
    </JobsSection>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query QueryJobsLatest {
        craft {
          entries(section: [jobs], limit: 10, orderBy: "postDate DESC") {
            id
            title
            postDate
            fullUri
            ... on Craft_Jobs {
              jobsIntro
            }
          }
        }
      }
    `}
    render={data => <JobsLatest data={data} {...props} />}
  />
);
