import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

export const JobsSection = styled('section')(
  {
    position: 'relative',
    padding: '0',

    [media('sm')]: {
      paddingBottom: '0',
    },

    [media('md')]: {
      padding: '0',
      height: 'auto',
      minHeight: 700,
    },

    '> div:first-child': {
      paddingTop: '33.33%',

      [media('md')]: {
        paddingTop: '480px',
      },
    },
  },

  ({ theme }) => ({
    background: theme.primaryColor,
  }),
);

export const ContainerWrapper = styled('div')(props => ({
  position: 'relative',
  padding: '0 20px 40px',

  [media('md')]: {
    padding: '0 20px 80px',
  },
}));

export const BoxesRow = styled('div')(props => ({
  position: 'relative',
  top: '-90px',
  margin: 'auto',
  marginBottom: '-90px',
  flexWrap: 'wrap',
  [media('sm')]: {
    display: 'flex',
    height: '100%',
    flexBasis: props.width,
    width: props.width,
    maxWidth: props.width,
    justifyContent: 'center',
    alignItems: props.alignItems,
  },
}));

export const BoxesColumn = styled('div')(props => ({
  [media('sm')]: {
    flexBasis: props.width,
    maxWidth: props.width,
    justifyContent: props.justifyContent,
  },
}));

export const TextBoxWrap = styled('div')({
  [media('sm')]: {
    padding: '0 10px',
  },
});

export const ButtonWrapper = styled('div')(props => ({
  padding: '20px 20px 0px',
  textAlign: 'center',
  [media('sm')]: {
    padding: '60px 20px 0px',
  },
}));
